<template>
  <b-modal
    id="create-role-modal"
    ref="create-role-modal"
    centered
    title="Create Role"
    ok-title="Create"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @hidden="resetModal"
    @ok="create"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="createRoleRules"
        tag="form"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  placeholder="Role Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'

import useRolesList from '@/views/admin/roles/roles-list/useRolesList'

export default {
  name: 'CreateRoleModal',
  components: {
    ValidationProvider,
    ValidationObserver,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
  },
  data() {
    return {
      isLoading: false,
      name: '',
      required,
    }
  },
  setup() {
    const {
      createRole,
    } = useRolesList()

    return {
      createRole,
    }
  },
  methods: {
    resetModal() {
      this.name = ''
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise(() => {
        this.$refs.createRoleRules.validate().then(success => {
          if (success) {
            const queryParams = {
              display_name: this.name,
            }
            this.isLoading = true
            this.createRole(queryParams)
              .then(response => {
                if (response.id) {
                  this.$router.push({ name: 'admin-role-edit', params: { id: response.id } })
                }
                this.$bvModal.hide('create-role-modal')
              })
              .finnaly(() => {
                this.isLoading = false
              })
          }
        })
      })
    },
  },
}
</script>
