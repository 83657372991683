import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ref, watch, computed } from '@vue/composition-api'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'

export default function useRolesList(root) {
  // Use toast
  const toast = useToast()

  const refRolesList = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'display_name', label: 'Role', sortable: true },
    { key: 'created_at', label: 'created at', sortable: true },
    { key: 'actions', class: 'table-actions' },
  ]
  const defaultPerPage = Number(localStorage.getItem('rolesPerPage'))
  const perPage = ref(defaultPerPage || 10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  let debounceTimeout = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refRolesList.value ? refRolesList.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refRolesList.value.refresh()
  }
  watch([currentPage, perPage, searchQuery],
    ([currentPageVal, perPageVal, searchQueryVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-roles-list', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-roles-list', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-roles-list', 'searchQuery', searchQueryVal)
        }, 250)
      }
    })

  const fetchRolesList = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }
    if (searchQuery.value) payloadData.name = searchQuery.value

    store
      .dispatch('app-roles/fetchRolesList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total } = response.data.meta

        callback(data)
        totalItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Roles' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const createRole = queryParams => axios
    .post('auth/admin-roles', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Role was created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating new Role',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteRole = ID => axios
    .delete(`auth/admin-roles/${ID}`)
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting Role',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting Role',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    totalItems,
    isSortDirDesc,
    refRolesList,
    fetchRolesList,
    deleteRole,
    createRole,

    refetchData,
  }
}
