import axios from '@axios'

export default {
  namespaced: true,
  state: {
    selectedRole: null,
  },
  getters: {
    getSelectedRole: state => state.selectedRole,
  },
  mutations: {
    SET_SELECTED_ROLE: (state, val) => {
      state.selectedRole = val
    },
  },
  actions: {
    async fetchRolesList(ctx, queryParams) {
      try {
        return await axios.get('/auth/admin-roles', { params: queryParams })
      } catch (error) {
        return error
      }
    },
  },
}
